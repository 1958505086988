import './footer.css';

function Footer() {
    return (<>
            <div className="footer">

                    <div className="footer_body">
                        <div className='footer_venez'>
                            <div className="footer_titre">VENEZ-NOUS VOIR</div>
                            <div className="footer_adresse_container">
                                <div className="footer_adresse_nom"> MT Cablage</div>
                                <div className="footer_adresse">
                                    2250A Rue Moreau,
                                <br/>Montréal (Québec)  <div className="footer_code_postal">H1W 2M5</div></div>

                                <div className="footer_tel">(514) 716-6016</div>

                            </div>
                        </div>

                        <div className="footer_social">
                            <div className="footer_social_titre">SUIVEZ-NOUS</div>
                            <div className="footer_social_logos">
                                <a className="footer_logo_fb" href="https://www.facebook.com/mtcablage/">
                                    </a>
                                <a className="footer_logo_Link" href="https://www.linkedin.com/company/mt-c%C3%A2blage/?originalSubdomain=ca">
                                   </a>
                            </div>
                        </div>
                </div>
                <div className="footer_fin">INFO@MTCABLAGE.COM</div>
            </div>
        </>);
}

export default Footer;