import {useNavigate} from 'react-router-dom';
import './home.css';
import Navbar from "../navBar/Navbar";
import Footer from "../footer/Footer";

function Home() {
    const navigate = useNavigate();
    const navigateRealisation = () => {
        navigate('/Realisations');
    };
    return (<div className="content">
            <Navbar/>
            <div className="accueil_photo"/>
            <div className="accueil_photo_text">
                <div className="accueil_titre_photo">MT Cablage</div>
                <div className="accueil_sous_titre_photo">solutions informatiques</div>
                <div className="accueil_btn_projet">
                    <button
                        onClick={navigateRealisation}
                        className="accueil_button">
                        DÉCOUVREZ NOS PROJETS
                    </button>
                </div>
            </div>
            <div className="apropos">
                <div className="apropos_gauche">
                    <div className="apropos_titre_text"> À PROPOS</div>
                    <div className="apropos_sous_titre_text">de MT Cablage</div>
                </div>
                <div className="apropos_droite">
                    <div className="apropos_haut">
                        <div className="apropos_text">
                            Bienvenue dans le monde des solutions informatiques innovantes !
                            Nous nous spécialisons dans la conception, l’installation et
                            la maintenance de systèmes de câblage réseau parfaitement adaptés aux besoins spécifiques de chaque entreprise.
                            Forts d'une expérience éprouvée et d'une équipe d'experts,
                            nous nous engageons à offrir des solutions fiables et performantes pour optimiser votre infrastructure technologique.
                            <br/>
                            <br/>
                            <br/>
                            Comprendre que chaque entreprise est unique est notre priorité.
                            C’est pourquoi nous proposons des solutions sur mesure qui répondent précisément à vos exigences.
                            Que vous ayez besoin d’une nouvelle installation de câblage,
                            de la mise à jour de votre infrastructure actuelle ou de services de dépannage,
                            nous sommes là pour vous accompagner à chaque étape du processus,
                            garantissant la qualité et l’efficacité de nos interventions.
                            <br/>
                            <br/>
                            <br/>
                            Faites confiance à notre expertise pour améliorer la performance et la fiabilité de votre réseau informatique.
                            Nous nous engageons à offrir un service client exceptionnel et des solutions innovantes,
                            vous permettant ainsi de vous concentrer sur ce qui compte le plus : le succès de votre entreprise.
                            Contactez-nous dès aujourd’hui pour découvrir comment nous pouvons transformer votre infrastructure technologique.
                        </div>
                    </div>
                </div>
            </div>
            <div className="apropos_bas_container">
                <div className="apropos_bas_gauche"></div>
                <div className="apropos_bas_droite">
                    Des solutions câblées pour un avenir connecté.
                </div>
            </div>


            <Footer/>
        </div>

    );
}

export default Home;